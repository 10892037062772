import React, { useState } from 'react'
import Typography from '@mui/material/Typography'

import MenuOne from 'components/Menu/MenuOne'

const MenuShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  const items = [
    { value: 'posts', label: 'Posts' },
    { value: 'projects', label: 'Projects' },
    { value: 'collections', label: 'Collections' }
  ]
  const [menu1Value, setMenu1Value] = useState(items[1].value)

  return (
    <div>
      <div>
        <Typography variant="h6" paragraph>
          Menu List Toolbar One
        </Typography>

        <div>
          <MenuOne
            items={items}
            onChange={(event, value) => {
              setMenu1Value(value)
            }}
            onChangeSearch={event => {
              console.log('search', event?.target?.value)
            }}
            disableSearch={menu1Value !== 'collections'}
          />
        </div>
      </div>
    </div>
  )
}

export default MenuShowcase
