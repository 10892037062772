import React from 'react'
import Typography from '@mui/material/Typography'

import ReactImageMagnify from 'components/ReactImageMagnify'
import { getScrambledImage } from 'models/ApiModelsDummy'

import imgScrambledSquareOriginal from 'assets/images/dummy/scrambled/square/original.jpg'

/* Mock Data */
const imgScrambledSquare = getScrambledImage()

const smallImage = {
  alt: `asdsad`,
  width: 724,
  height: 600,
  src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Barrel_sponge_%28Xestospongia_testudinaria%29.jpg/724px-Barrel_sponge_%28Xestospongia_testudinaria%29.jpg'
}

const largeImage2 = {
  alt: `asdsad`,
  width: 927,
  height: 768,
  src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Barrel_sponge_%28Xestospongia_testudinaria%29.jpg/927px-Barrel_sponge_%28Xestospongia_testudinaria%29.jpg'
}

const ReactImageMagnifyShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  return (
    <div>
      <div className="mb-6">
        <Typography variant="body1" paragraph>
          enlargedImagePosition: beside
        </Typography>

        <ReactImageMagnify
          smallImage={smallImage}
          largeImage={largeImage2}
          smallImageContainerDimensions={{
            width: smallImage.width,
            height: smallImage.height
          }}
          enlargedImageContainerDimensions={{ width: 300, height: 300 }}
        />
      </div>

      <div className="mb-6">
        <Typography variant="body1" paragraph>
          enlargedImagePosition: over
        </Typography>

        <ReactImageMagnify
          smallImage={smallImage}
          largeImage={largeImage2}
          smallImageContainerDimensions={{
            width: smallImage.width,
            height: smallImage.height
          }}
          enlargedImagePosition="over"
          enlargedImageContainerDimensions={{ width: 300, height: 300 }}
          smallImageLoading={false}
          disableLens={false}
        />
      </div>

      <div className="mb-6">
        <Typography variant="body1" paragraph>
          enlargedImagePosition: over-small
        </Typography>

        <ReactImageMagnify
          smallImage={smallImage}
          largeImage={largeImage2}
          smallImageContainerDimensions={{
            width: smallImage.width,
            height: smallImage.height
          }}
          enlargedImagePosition="over-small"
          enlargedImageContainerDimensions={{ width: 300, height: 300 }}
          smallImageLoading={false}
          disableLens={false}
        />
      </div>

      <div className="mb-6">
        <Typography variant="body1" paragraph>
          With Scrambled Image
        </Typography>

        <ReactImageMagnify
          smallImage={{
            src: imgScrambledSquareOriginal,
            width: 600,
            height: 600
          }}
          largeImage={imgScrambledSquare}
          smallImageContainerDimensions={{
            width: 500,
            height: 500
          }}
          // enlargedImagePosition="over-small"
          enlargedImageContainerDimensions={{ width: 300, height: 300 }}
          smallImageLoading={false}
          disableLens={false}
          scrambledHelper
        />
      </div>

      <div className="mb-6">
        <Typography variant="body1" paragraph>
          enlargedImagePosition: over With Scrambled Image
        </Typography>

        <ReactImageMagnify
          smallImage={{
            src: imgScrambledSquareOriginal,
            width: 600,
            height: 600
          }}
          largeImage={imgScrambledSquare}
          smallImageContainerDimensions={{
            width: 500,
            height: 500
          }}
          enlargedImagePosition="over"
          enlargedImageContainerDimensions={{ width: 300, height: 300 }}
          smallImageLoading={false}
          disableLens={false}
          scrambledHelper
        />
      </div>

      <div className="mb-6">
        <Typography variant="body1" paragraph>
          enlargedImagePosition: over-small With Scrambled Image
        </Typography>

        <ReactImageMagnify
          smallImage={{
            src: imgScrambledSquareOriginal,
            width: 600,
            height: 600
          }}
          largeImage={imgScrambledSquare}
          smallImageContainerDimensions={{
            width: 500,
            height: 500
          }}
          enlargedImagePosition="over-small"
          enlargedImageContainerDimensions={{ width: 300, height: 300 }}
          smallImageLoading={false}
          disableLens={false}
          scrambledHelper
        />
      </div>
    </div>
  )
}

export default ReactImageMagnifyShowcase
