import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import Divider from '@mui/material/Divider'

type TypographyShowcaseProps = {}

const TextContainer = styled('div')({
  '& > *': {
    display: 'block',
    marginBottom: '10px'
  }
})

const TypographyShowcase: React.FC<TypographyShowcaseProps> = props => {
  return (
    <div style={{ padding: '30px 0' }}>
      <Typography variant="h4">Typography Showcase</Typography>
      <Divider style={{ margin: '20px 0' }} />
      <TextContainer>
        <Typography variant="h1">H1 - Example Of H1 Typography</Typography>
        <Typography variant="h2">H2 - Example Of H2 Typography</Typography>
        <Typography variant="h3">H3 - Example Of H3 Typography</Typography>
        <Typography variant="h4">H4 - Example Of H4 Typography</Typography>
        <Typography variant="h5">H5 - Example Of H5 Typography</Typography>
        <Typography variant="h6">H6 - Example Of H6 Typography</Typography>
        <Typography variant="subtitle1">Subtitle 1 - Example Of Subtitle 1 Typography</Typography>
        <Typography variant="subtitle2">Subtitle 2 - Example Of Subtitle 2 Typography</Typography>
        <Typography variant="body1">Body 1 - Example Of Body 1 Typography</Typography>
        <Typography variant="body2">Body 2 - Example Of Body 2 Typography</Typography>
        <Typography variant="button">Button - Example Of Button Typography</Typography>
        <Typography variant="caption">Caption - Example Of Caption Typography</Typography>
        <Typography variant="overline">Overline - Example Of Overline Typography</Typography>
      </TextContainer>
    </div>
  )
}

export default TypographyShowcase
