import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet'
import { Dispatch, bindActionCreators } from 'redux'
import { RootActionType, RootState } from 'duck'
import { connect } from 'react-redux'
import Collapse from '@mui/material/Collapse'
import { ReduxProps } from 'utils/Types'
import Button from 'components/Button'
import DialogShowcase from './DialogShowcase'
import ReactImageMagnifyShowcase from './ReactImageMagnifyShowcase'
import GridShowcase from './GridShowcase'
import SliderShowcase from './SliderShowcase'
import BannerShowcase from './BannerShowcase'
import ButtonShowcase from './ButtonShowcase'
import DropdownShowcase from './DropdownShowcase'
import FormShowcase from './FormShowcase'
import IconsShowcase from './IconsShowcase'
import ImageShowcase from './ImageShowcase'
import LinkShowcase from './LinkShowcase'
import ListVirtualizedShowcase from './ListVirtualizedShowcase'
import LoadingShowcase from './LoadingShowcase'
import MenuShowcase from './MenuShowcase'
import SelectShowcase from './SelectShowcase'
import StepperShowcase from './StepperShowcase'
import TabsShowcase from './TabsShowcase'
import TooltipShowcase from './TooltipShowcase'
import TypographyShowcase from './TypographyShowcase'
import { EMPTY_OBJECT } from 'appConstants'

import stylesGlobal from 'stylesGlobal.module.scss'

const STYLE_SIDEBAR = {
  width: 300
}

const COMPONENTS = [
  {
    label: 'Components',
    components: [
      {
        label: 'banner',
        component: BannerShowcase
      },
      {
        label: 'Button',
        component: ButtonShowcase
      },
      {
        label: 'Dialogs',
        component: DialogShowcase
      },
      {
        label: 'Dropdown',
        component: DropdownShowcase
      },
      {
        label: 'Form',
        component: FormShowcase
      },
      {
        label: 'Grid',
        component: GridShowcase
      },
      {
        label: 'Icons',
        component: IconsShowcase
      },
      {
        label: 'Image',
        component: ImageShowcase
      },
      {
        label: 'Link',
        component: LinkShowcase
      },
      {
        label: 'List Virtualized',
        component: ListVirtualizedShowcase
      },
      {
        label: 'Loading',
        component: LoadingShowcase
      },
      {
        label: 'Menu',
        component: MenuShowcase
      },
      {
        label: 'React Magnify',
        component: ReactImageMagnifyShowcase
      },
      {
        label: 'Select',
        component: SelectShowcase
      },
      {
        label: 'Slider',
        component: SliderShowcase
      },
      {
        label: 'Stepper',
        component: StepperShowcase
      },
      {
        label: 'Tabs',
        component: TabsShowcase
      },
      {
        label: 'Typography',
        component: TypographyShowcase
      },
      {
        label: 'Tooltip',
        component: TooltipShowcase
      }
    ]
  }
]

const setComponent = (components: any, onClick: Function, activeLabel: string) => {
  return components.map((item: any, index: number) => {
    if (!item.components) {
      return (
        <Button
          key={index}
          style={
            activeLabel === item.label ? { background: 'rgba(255, 255, 255, 0.12)' } : undefined
          }
          align="left"
          onClick={() => {
            onClick(item)
          }}
          fullWidth
        >
          {item.label}
        </Button>
      )
    }

    return <ButtonGroup key={index} item={item} onClick={onClick} activeLabel={activeLabel} />
  })
}

const mapStateToProps = (state: RootState): RootState => state

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) => bindActionCreators({}, dispatch)

type UIShowcaseProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const ButtonGroup: React.FC<any> = props => {
  const { activeLabel, item, onClick } = props
  const [open, setOpen] = useState(true)

  return (
    <div>
      <Button
        align="left"
        onClick={() => {
          setOpen(prev => !prev)
        }}
        fullWidth
      >
        {item.label}
      </Button>

      <Collapse in={open}>
        <div className="pl-6">{setComponent(item.components, onClick, activeLabel)}</div>
      </Collapse>
    </div>
  )
}

const UIShowcase: React.FC<UIShowcaseProps> = props => {
  const [activePage, setActivePage] = useState<any>(COMPONENTS[0].components[9])
  const ComponentName = activePage?.component || null
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const additionalProps =
    activePage?.label === 'List Virtualized'
      ? {
          scrollRef
        }
      : EMPTY_OBJECT

  return (
    <>
      <Helmet>
        <title>UI Showcase</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="flex h-1/100 min-h-0 flex-auto">
        <div className="overflow-auto" style={STYLE_SIDEBAR}>
          {setComponent(
            COMPONENTS,
            (props: any) => {
              setActivePage(props)
            },
            activePage?.label
          )}
        </div>

        <div ref={scrollRef} className="w-1/100 min-w-0 flex-auto overflow-auto p-6">
          <Typography variant="h6">UI Showcase</Typography>

          {activePage?.label ? (
            <Typography className="mt-2" variant="h4">
              {activePage?.label}
            </Typography>
          ) : null}

          <div className={clsx('mb-6 pt-6', stylesGlobal.BorderBottomOnSurface)} />

          {ComponentName ? <ComponentName {...additionalProps} /> : null}
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UIShowcase)
