import React, { SyntheticEvent, useCallback, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import clsx from 'clsx'

import FixedSizeList from 'components/ListVirtualized/FixedSizeList'
import MasonryGrid from 'components/ListVirtualized/MasonryGrid'
import SimpleList from 'components/ListVirtualized/SimpleList'
import SimpleMasonryGrid from 'components/ListVirtualized/SimpleMasonryGrid'

import stylesGlobal from 'stylesGlobal.module.scss'

const DUMMY_DATA = [...Array(50)].map((u, i) => i)
DUMMY_DATA.shift()
const DUMMY_MASONRYDATA = [...Array(50)].map(() => ({
  // width: 300,
  height: Math.floor(Math.random() * (500 - 200 + 1)) + 200
}))

const ListVirtualizedShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  const { scrollRef } = props
  const [tabValue, setTabValue] = useState<
    | 'fixedList'
    | 'fixedListAutoheight'
    | 'fixedListWindow'
    | 'simpleList'
    | 'masonry'
    | 'masonryAutoheight'
    | 'simpleMasonry'
    | string
  >('fixedListWindow')

  const dataLength = DUMMY_DATA.length

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const calculateCellHeight = useCallback(
    (index: number, width: number, item: any, defaultHeight: number) => {
      return item.height
    },
    []
  )

  const onEndofGrid = useCallback(() => {
    console.log('onEndOfGrid')
  }, [])

  const onScroll = useCallback((props: any) => {
    // console.log('onScroll', props)
  }, [])

  return (
    <div>
      <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Fixed List" value="fixedList" />
        <Tab label="Fixed List Autoheight" value="fixedListAutoheight" />
        <Tab label="Fixed List Window" value="fixedListWindow" />
        <Tab label="Simple List" value="simpleList" />
        <Tab label="Masonry" value="masonry" />
        <Tab label="Masonry Autoheight" value="masonryAutoheight" />
        <Tab label="Simple Masonry" value="simpleMasonry" />
      </Tabs>

      {tabValue === 'fixedList' ? (
        <div
          style={{
            height: 500
          }}
        >
          <FixedSizeList
            debug
            dataLength={dataLength}
            config={{
              default: {
                // columns: 1,
                maxWidth: 900,
                defaultColumnWidth: 300,
                rowHeight: 300,
                gutter: 24,
                paddingX: 24,
                paddingY: 24
                // square: true
              }
            }}
            onEndOfGrid={onEndofGrid}
            onScroll={onScroll}
            renderItem={({ index, width, height }) => {
              if (!DUMMY_DATA[index]) return null

              const item = DUMMY_DATA[index]

              return (
                <div className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}>
                  {item} {width} {height}
                </div>
              )
            }}
          />
        </div>
      ) : null}

      {tabValue === 'fixedListAutoheight' ? (
        <FixedSizeList
          debug
          autoHeight
          dataLength={dataLength}
          config={{
            default: {
              maxWidth: 900,
              defaultColumnWidth: 250,
              rowHeight: 300,
              paddingX: 16,
              paddingTop: 60,
              paddingBottom: 48,
              // paddingx: 36,
              gutter: 24
            }
          }}
          onEndOfGrid={onEndofGrid}
          onScroll={onScroll}
          renderItem={({ index, width, height }) => {
            if (!DUMMY_DATA[index]) return null

            const item = DUMMY_DATA[index]

            return (
              <div className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}>
                {item} {width} {height}
              </div>
            )
          }}
          scrollRef={scrollRef}
        />
      ) : null}

      {tabValue === 'fixedListWindow' ? (
        <div
          style={{
            height: 500
          }}
        >
          <FixedSizeList
            debug
            dataLength={dataLength}
            config={{
              default: {
                // columns: 1,
                maxWidth: 900,
                defaultColumnWidth: 300,
                rowHeight: 300,
                gutter: 24,
                paddingX: 24,
                paddingY: 24,
                square: true
              }
            }}
            onEndOfGrid={onEndofGrid}
            onScroll={onScroll}
            renderItem={({ index, width, height }) => {
              if (!DUMMY_DATA[index]) return null

              const item = DUMMY_DATA[index]

              return (
                <div className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}>
                  {item} {width} {height}
                </div>
              )
            }}
          />
        </div>
      ) : null}

      {tabValue === 'masonry' ? (
        <div
          style={{
            height: 500
          }}
        >
          <MasonryGrid
            className="h-full"
            calculateCellHeight={calculateCellHeight}
            data={DUMMY_MASONRYDATA}
            onEndOfGrid={onEndofGrid}
            onScroll={onScroll}
            config={{
              default: {
                maxWidth: 900,
                defaultColumnWidth: 300
                // gutter: 24
              }
            }}
            renderItem={({ item, style, width, height }) => {
              return (
                <div
                  className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}
                  style={{
                    height: item.height
                  }}
                >
                  {item.height} {width} {height}
                </div>
              )
            }}
          />
        </div>
      ) : null}

      {tabValue === 'masonryAutoheight' ? (
        <MasonryGrid
          autoHeight
          className="h-full"
          calculateCellHeight={calculateCellHeight}
          data={DUMMY_MASONRYDATA}
          onEndOfGrid={onEndofGrid}
          onScroll={onScroll}
          config={{
            default: {
              maxWidth: 900,
              defaultColumnWidth: 300,
              defaultRowHeight: 200
              // gutter: 24
            }
          }}
          renderItem={({ item, style, width, height }) => {
            return (
              <div
                className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}
                style={{
                  height: item.height
                }}
              >
                {item.height} {width} {height}
              </div>
            )
          }}
          scrollRef={scrollRef}
        />
      ) : null}

      {tabValue === 'simpleList' ? (
        <>
          <SimpleList
            dataLength={DUMMY_DATA.length}
            config={{
              default: {
                maxWidth: 900,
                columns: 3,
                // defaultColumnWidth: 250,
                rowHeight: 250,
                // paddingLeft: 24,
                // paddingRight: 24
                // paddingX: 24,
                paddingTop: 60,
                paddingBottom: 48,
                // paddingY: 60,
                gutter: 24
                // square: true
              }
            }}
            onEndOfGrid={onEndofGrid}
            // onScroll={onScroll}
            renderItem={({ index, width, height }) => {
              const item = DUMMY_DATA[index]

              return (
                <div className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}>
                  {item} {width} {height}
                </div>
              )
            }}
            scrollRef={scrollRef}
          />

          {/* <SimpleList
            dataLength={DUMMY_DATA.length}
            config={{
              default: {
                maxWidth: 900,
                columns: 3,
                // defaultColumnWidth: 250,
                rowHeight: 250,
                // paddingLeft: 24,
                // paddingRight: 24
                paddingX: 24,
                // paddingTop: 60,
                // paddingBottom: 48,
                paddingY: 60
                // gutter: 24
                // square: true
              }
            }}
            // onEndOfGrid={onEndofGrid}
            // onScroll={onScroll}
            renderItem={({ index, width, height }) => {
              const item = DUMMY_DATA[index]

              return (
                <div className={clsx('h-full w-full', stylesGlobal.BorderOnSurface)}>
                  {item} {width} {height}
                </div>
              )
            }}
            scrollRef={scrollRef}
          /> */}
        </>
      ) : null}

      {tabValue === 'simpleMasonry' ? (
        <SimpleMasonryGrid
          data={DUMMY_MASONRYDATA}
          config={{
            default: {
              maxWidth: 900,
              defaultColumnWidth: 300
              // gutter: 24
            }
          }}
          renderItem={({ item }: { item: any }) => {
            return (
              <div
                className={clsx('w-full', stylesGlobal.BorderOnSurface)}
                style={{
                  height: item.height
                }}
              >
                {item.height}
              </div>
            )
          }}
        />
      ) : null}
    </div>
  )
}

export default ListVirtualizedShowcase
