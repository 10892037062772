import React from 'react'
import Divider from '@mui/material/Divider'
import SelectBase from 'components/Select/SelectBase'
import Typography from '@mui/material/Typography'

const items = [
  {
    label: 'mp4',
    value: 'mp4'
  },
  {
    label: 'mov',
    value: 'mov'
  }
]

const SelectShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  return (
    <div style={{ padding: '30px 0' }}>
      <Typography variant="h4">Select Showcase</Typography>

      <Divider style={{ margin: '20px 0' }} />

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Select
        </Typography>

        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
        />

        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
          inputAlign="right"
        />

        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
          borderless
        />
      </div>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Select Small
        </Typography>
        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
          size="small"
        />

        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
          size="small"
          inputAlign="right"
        />

        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
          size="small"
          borderless
        />
      </div>

      <div
        style={{
          maxWidth: '500px'
        }}
      >
        <Typography variant="h6" paragraph>
          Select Full Width
        </Typography>

        <SelectBase
          // value={clipSpeed}
          onChange={() => {}}
          items={items}
          fullWidth
        />
      </div>
    </div>
  )
}

export default SelectShowcase
