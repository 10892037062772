import React from 'react'
import OpenInNew from '@mui/icons-material/OpenInNew'

import Link from 'components/Link'

const LinkShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  return (
    <div>
      <div>
        <Link href="#">Link Default</Link>
      </div>

      <div>
        <Link href="#" color="primary">
          Link Primary
        </Link>
      </div>

      <div>
        <Link href="#" color="secondary">
          Link Secondary
        </Link>
      </div>

      <div>
        <Link href="#" color="light">
          Link Light
        </Link>
      </div>

      <div>
        <Link href="#" addOnRight={<OpenInNew />} target="_blank">
          Terms and Conditions
        </Link>
      </div>
    </div>
  )
}

export default LinkShowcase
