import React from 'react'
import Link from 'components/Link'
import Tooltip from 'components/Tooltip'

type TooltipShowcaseProps = {}

const TooltipShowcase: React.FC<TooltipShowcaseProps> = props => {
  return (
    <div style={{ padding: '30px 0' }}>
      <div>
        <div style={{ paddingRight: '10px' }}>
          <Tooltip title="Tooltip" aria-label="tooltip">
            <span>Hover</span>
          </Tooltip>
        </div>

        <div style={{ paddingRight: '10px' }}>
          <Tooltip title="Tooltip" open={true} aria-label="tooltip">
            <span>Always Show</span>
          </Tooltip>
        </div>
      </div>

      <div style={{ paddingTop: '60px' }}>
        Original Docs -{' '}
        <Link href="https://material-ui.com/components/tooltips/" target="_blank">
          Material UI Tooltip
        </Link>
      </div>
    </div>
  )
}

export default TooltipShowcase
