import React, { useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { CircularLoading } from 'components/Loading/CircularLoading'
import Loading from 'components/Loading'
import LinearProgressTextOverlay from 'components/LinearProgress/LinearProgressTextOverlay'

type LoadingShowcaseProps = {}

let loadingWithPauseInterval: any

const LoadingShowcase: React.FC<LoadingShowcaseProps> = props => {
  const [loadingWithPauseValue, setLoadingWithPauseValue] = useState(60)
  const [loadingWithPauseStatus, setLoadingWithPauseStatus] = useState(true)

  useEffect(() => {
    if (!loadingWithPauseInterval) {
      loadingWithPauseInterval = setInterval(() => {
        if (loadingWithPauseStatus) {
          setLoadingWithPauseValue(prev => {
            if (prev >= 100) return 1
            return prev + 10
          })
        }
      }, 1000)
    }

    return () => {
      clearInterval(loadingWithPauseInterval)
      loadingWithPauseInterval = undefined
    }
  }, [loadingWithPauseStatus])

  return (
    <div style={{ padding: '30px 0' }}>
      <Typography variant="h4" component="h2" paragraph>
        Loading Showcase
      </Typography>

      <div className="mb-6">
        <CircularLoading loading={true} disableShrink />
        <CircularLoading variant="determinate" value={60} />
        <CircularLoading
          variant="determinate"
          value={loadingWithPauseValue}
          size={48}
          onClickPause={() => {
            setLoadingWithPauseStatus(false)
          }}
          onClickPlay={() => {
            setLoadingWithPauseStatus(true)
          }}
          pause={!loadingWithPauseStatus}
        />
      </div>

      <div className="mb-6">
        <LinearProgress
          variant="determinate"
          color="secondary"
          // className={styles.ProgressBar}
          value={60}
        />
      </div>

      <div className="mb-6">
        <LinearProgressTextOverlay
          // className={styles.LinearProgressStorage}
          value={60}
          contentLeft={
            <Typography className="pr-2" variant="overline" component="div">
              STORAGE
            </Typography>
          }
          contentRight={
            <div className="text-right">
              <Typography variant="button" component="div">
                20 / $10 used
              </Typography>
            </div>
          }
        />
      </div>

      <div>
        <Typography variant="h4">Loading Logo Showcase</Typography>
        <Loading />
        <Loading size="small" />
      </div>
    </div>
  )
}

export default LoadingShowcase
