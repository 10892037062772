import React, { useState } from 'react'
import Typography from '@mui/material/Typography'

import TabsOutlined, { TabOutlined } from 'components/Tabs/TabsOutlined'
import TabsOne, { TabOne } from 'components/Tabs/TabsOne'

const TabsShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  const [tabValue, setTabValue] = useState(0)

  return (
    <div>
      <div className="mb-8">
        <Typography variant="h5">Tabs Outlined</Typography>

        <div className="flex">
          <div className="m-1">
            <TabsOutlined value={tabValue} onChange={setTabValue}>
              <TabOutlined label="Personal" />
              <TabOutlined label="Public" />
            </TabsOutlined>
          </div>

          <div className="m-1">
            <TabsOutlined value={tabValue} onChange={setTabValue} size="small">
              <TabOutlined label="Personal" />
              <TabOutlined label="Public" />
            </TabsOutlined>
          </div>
        </div>

        <div className="flex">
          <div className="m-1">
            <TabsOutlined value={tabValue} onChange={setTabValue} activeBordered>
              <TabOutlined label="Personal" />
              <TabOutlined label="Public" />
            </TabsOutlined>
          </div>

          <div className="m-1">
            <TabsOutlined value={tabValue} onChange={setTabValue} size="small" activeBordered>
              <TabOutlined label="Personal" />
              <TabOutlined label="Public" />
            </TabsOutlined>
          </div>
        </div>

        <div className="flex">
          <div className="m-1">
            <TabsOutlined value={tabValue} onChange={setTabValue} activeBordered compact>
              <TabOutlined label="Personal" />
              <TabOutlined label="Public" />
            </TabsOutlined>
          </div>

          <div className="m-1">
            <TabsOutlined
              value={tabValue}
              onChange={setTabValue}
              size="small"
              activeBordered
              compact
            >
              <TabOutlined label="Personal" />
              <TabOutlined label="Public" />
            </TabsOutlined>
          </div>
        </div>
      </div>

      <div>
        <Typography variant="h5">Tabs One</Typography>

        <div>
          <TabsOne value={tabValue} onChange={setTabValue}>
            <TabOne label="Trending" />
            <TabOne label="Recent" />
          </TabsOne>
        </div>
      </div>
    </div>
  )
}

export default TabsShowcase
