import React, { useState } from 'react'
import Typography from '@mui/material/Typography'

import Button from 'components/Button'
import Stepper from 'components/Stepper'

const dataSteps = [{ title: 'CURATE COLLECTION' }, { title: 'DETAILS' }, { title: 'SUBMIT' }]

const StepperShowcase: React.FC<{}> = props => {
  const [activeStep, setActiveStep] = useState(0)

  return (
    <div>
      <div className="mb-8">
        <Typography variant="h5">Stepper</Typography>

        <div className="py-6">
          <Stepper className="mb-6" data={dataSteps} activeIndex={activeStep} />

          <Stepper className="mb-6" data={dataSteps} activeIndex={activeStep} singleView />
        </div>

        <div className="flex">
          <Button
            disabled={activeStep < 1}
            onClick={() => {
              setActiveStep(prev => prev - 1)
            }}
          >
            Prev
          </Button>

          <Button
            disabled={activeStep + 1 >= dataSteps.length}
            onClick={() => {
              setActiveStep(prev => prev + 1)
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StepperShowcase
