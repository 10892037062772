import React, { MouseEventHandler } from 'react'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import { RootActionType, RootState } from 'duck'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { apiSelectors } from 'duck/ApiDuck'
import {
  ErrorDialog,
  InformationDialog,
  ConfirmationDialog,
  ChangeEmailDialog,
  FormDialog,
  DialogNameList,
  DialogNameType,
  MonetizationDialog,
  dialogActions
} from 'duck/AppDuck/DialogDuck'
import { ReduxProps } from 'utils/Types'

const mapStateToProps = (state: RootState) => ({
  currentProject: apiSelectors.currentProject(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      addDialog: dialogActions.addDialog
    },
    dispatch
  )

export type DialogShowcaseProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
  scrollRef?: React.MutableRefObject<any>
}

const COMBINED_DIALOGS = {
  ...ErrorDialog,
  ...InformationDialog,
  ...ConfirmationDialog,
  ...ChangeEmailDialog,
  ...MonetizationDialog,
  ...FormDialog
}

const DialogShowcase: React.FC<DialogShowcaseProps> = props => {
  const { addDialog } = props

  return (
    <div>
      <Typography variant="h6" paragraph>
        Dialog Material UI
      </Typography>

      <Typography variant="h6" paragraph>
        Dialog Component
      </Typography>

      {Object.keys(COMBINED_DIALOGS).map(dialogKey => {
        const dialogKeyCasted = dialogKey as DialogNameList
        const dialogName = COMBINED_DIALOGS[dialogKeyCasted] as DialogNameType

        let onClick: MouseEventHandler = () => addDialog({ [dialogName]: { dialogName } })

        if (dialogName === 'information') {
          onClick = () =>
            addDialog({
              information: {
                dialogName: 'information',
                content: 'dasdadas dasdas dasd asdasd asd',
                title: 'asdasdasd asdasdas'
              }
            })
        }

        if (dialogName === 'error') {
          onClick = () =>
            addDialog({
              error: {
                dialogName: 'error',
                content: 'asdasdas dasd asda sdasd asdas d',
                title: 'You have something wrong'
              }
            })
        }
        if (dialogName === 'error.uploadFailed') {
          onClick = () =>
            addDialog({
              'error.uploadFailed': {
                dialogName: 'error.uploadFailed',
                content: {
                  customTitle: 'asdadasdasdsad',
                  errorList: ['asdsadasdasd', 'asdasdasdasd', 'asdasdasdasd'],
                  count: 3
                },
                title: 'asdasdasd'
              }
            })
        }

        if (dialogName === ErrorDialog.NOT_ENOUGH_IMAGES) {
          onClick = () =>
            addDialog({
              [ErrorDialog.NOT_ENOUGH_IMAGES]: {
                dialogName: ErrorDialog.NOT_ENOUGH_IMAGES,
                content: {
                  isLess: true,
                  isMore: false,
                  inspirationCount: 10,
                  aestheticCount: 10,
                  maxInspiration: 10,
                  maxAesthetic: 100,
                  minInspiration: 30,
                  minAesthetic: 40,
                  isDoubleInput: true
                }
              }
            })
        }
        if (dialogName === ErrorDialog.TOO_MANY_IMAGES) {
          onClick = () =>
            addDialog({
              [ErrorDialog.TOO_MANY_IMAGES]: {
                dialogName: ErrorDialog.TOO_MANY_IMAGES,
                content: {
                  isLess: false,
                  isMore: true,
                  inspirationCount: 10,
                  aestheticCount: 10,
                  maxInspiration: 10,
                  maxAesthetic: 100,
                  minInspiration: 30,
                  minAesthetic: 40,
                  isDoubleInput: true
                }
              }
            })
        }
        if (dialogName === ErrorDialog.REUSED_COLLECTION) {
          onClick = () =>
            addDialog({
              [ErrorDialog.REUSED_COLLECTION]: {
                dialogName: ErrorDialog.REUSED_COLLECTION,
                content: {
                  process: 'aesthetic'
                }
              }
            })
        }

        if (dialogName === ConfirmationDialog.CONFIRMATION) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.CONFIRMATION]: {
                dialogName: ConfirmationDialog.CONFIRMATION,
                content: 'dasdadas dasdas dasd asdasd asd',
                title: 'asdasdasd asdasdas',
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }
        if (dialogName === ConfirmationDialog.REMOVE_PAYMENT_METHOD) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.REMOVE_PAYMENT_METHOD]: {
                dialogName: ConfirmationDialog.REMOVE_PAYMENT_METHOD,
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }
        if (dialogName === ConfirmationDialog.DELETE_PROJECT) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.DELETE_PROJECT]: {
                dialogName: ConfirmationDialog.DELETE_PROJECT,
                content: { projectName: 'asasa' },
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }

        if (dialogName === ConfirmationDialog.DELETE_COLLECTION) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.DELETE_COLLECTION]: {
                dialogName: ConfirmationDialog.DELETE_COLLECTION,
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }
        if (dialogName === ConfirmationDialog.LOGOUT) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.LOGOUT]: {
                dialogName: ConfirmationDialog.LOGOUT,
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }
        if (dialogName === ConfirmationDialog.PAUSE) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.PAUSE]: {
                dialogName: ConfirmationDialog.PAUSE,
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }
        if (dialogName === ConfirmationDialog.DELETE_POST_ADMIN) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.DELETE_POST_ADMIN]: {
                dialogName: ConfirmationDialog.DELETE_POST_ADMIN,
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }
        if (dialogName === ConfirmationDialog.DELETE_POST_OWNER) {
          onClick = () =>
            addDialog({
              [ConfirmationDialog.DELETE_POST_OWNER]: {
                dialogName: ConfirmationDialog.DELETE_POST_OWNER,
                yesAction: { label: 'asas', actions: [() => console.log('yes')] }
              }
            })
        }

        return (
          <Button variant="outlined" key={dialogKey} onClick={onClick}>
            {dialogKey}
          </Button>
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogShowcase)
