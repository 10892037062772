import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/system'

import Button from 'components/Button'
import ButtonText from 'components/Button/ButtonText'
import ButtonEmoji from 'components/Button/ButtonEmoji'
import ButtonImage from 'components/Button/ButtonImage'
import ButtonProject from 'components/Button/ButtonProject'
import ButtonIconVertical from 'components/Button/ButtonIconVertical'
import ButtonSketchTool from 'components/Button/ButtonSketchTool'
import { route } from 'routes'
import { TextTransform } from 'utils/TextUtils'
import ButtonMaterialShowcase from './ButtonMaterialShowcase'
import UndoIcon from '@mui/icons-material/Undo'

import freeformImg from 'assets/images/freeform.png'

type ButtonShowcaseProps = {
  scrollRef?: React.MutableRefObject<any>
}

const ButtonContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: '20px',
  '& > *': {
    marginRight: '10px'
  }
})

const buttonVariants = [undefined, 'outlined', 'contained']
const buttonColors = ['primary', 'secondary', 'info', 'warning', 'success', 'error', 'light']

const ButtonShowcase: React.FC<ButtonShowcaseProps> = props => {
  return (
    <div>
      <ButtonMaterialShowcase />

      <Typography variant="h6" paragraph>
        Button Component
      </Typography>

      {buttonVariants.map((variant: any, index: number) => {
        return buttonColors.map((color: any, index: number) => {
          return (
            <ButtonContainer key={index}>
              <Button color={color} variant={variant} size="large">
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant}>
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} size="small">
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} size="xsmall">
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} size="large" startIcon={<AddIcon />}>
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} startIcon={<AddIcon />}>
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} size="small" startIcon={<AddIcon />}>
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} size="xsmall" startIcon={<AddIcon />}>
                {TextTransform.textCase(color)}
              </Button>

              <Button color={color} variant={variant} size="large" icon>
                <AddIcon />
              </Button>

              <Button color={color} variant={variant} icon>
                <AddIcon />
              </Button>

              <Button color={color} variant={variant} size="small" icon>
                <AddIcon />
              </Button>

              <Button color={color} variant={variant} size="xsmall" icon>
                <AddIcon />
              </Button>

              <Button color={color} variant={variant} startIcon={<AddIcon />} disabled>
                Disabled
              </Button>
            </ButtonContainer>
          )
        })
      })}

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Tooltip
        </Typography>

        <div>
          <Button tooltip="Tooltip">BUTTON TOOLTIP</Button>

          <Button tooltip="Tooltip" disabled>
            BUTTON TOOLTIP DISABLED
          </Button>
        </div>
      </div>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Sizes
        </Typography>

        <div>
          <Button variant="contained" color="secondary" size="large">
            Large
          </Button>

          <Button variant="contained" color="secondary">
            DEFAULT / MEDIUM
          </Button>

          <Button variant="contained" color="secondary" size="small">
            SMALL
          </Button>

          <Button variant="contained" color="secondary" size="xsmall">
            XSMALL
          </Button>
        </div>
      </div>

      <Typography variant="h6" paragraph>
        Button as Router Link
      </Typography>

      <ButtonContainer>
        <Button variant="contained" color="secondary" to={route.PROFILE.getUrl()}>
          GO TO PROFILE
        </Button>

        <Button
          color="secondary"
          href="https://create.playform.io"
          onClick={() => {
            console.log('onCLick')
          }}
        >
          GO TO PLAYFORM
        </Button>
      </ButtonContainer>

      <Typography variant="h6" paragraph>
        Button Emoji
      </Typography>

      <ButtonContainer>
        <ButtonEmoji emoji="clap" sizeEmoji={20} />
        <ButtonEmoji emoji="clap" sizeEmoji={20} value={20} />
        <ButtonEmoji emoji="clap" sizeEmoji={20} value={20} active />
      </ButtonContainer>

      <Typography variant="h6" paragraph>
        Button Image
      </Typography>

      <ButtonContainer>
        <Box style={{ width: 300 }}>
          <ButtonImage title="tes" image={freeformImg} />
        </Box>
      </ButtonContainer>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Button Sketch Tool
        </Typography>

        <div>
          <div className="inline-flex">
            <ButtonSketchTool icon={<UndoIcon />} label="UNDO" />

            <ButtonSketchTool icon={<UndoIcon />} label="UNDO" active />

            <ButtonSketchTool icon={<UndoIcon />} label="UNDO" disabled active />
          </div>
        </div>
      </div>

      <Typography variant="h6" paragraph>
        Button With Icon Vertical
      </Typography>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />}>Default</ButtonIconVertical>

        <ButtonIconVertical icon={<AddIcon />} disabled>
          Default
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />} compact>
          Compact
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />} color="light">
          Light
        </ButtonIconVertical>

        <ButtonIconVertical icon={<AddIcon />} color="light" disabled>
          Light
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />} color="secondary">
          Secondary
        </ButtonIconVertical>

        <ButtonIconVertical icon={<AddIcon />} color="secondary" disabled>
          Secondary
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />} variant="outlined">
          ADD
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />} variant="outlined" color="light">
          ADD
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <ButtonIconVertical icon={<AddIcon />} variant="outlined" color="secondary">
          ADD
        </ButtonIconVertical>
      </div>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Button Text
        </Typography>

        <ButtonContainer>
          <ButtonText>
            <Typography variant="overline">Button Text Default</Typography>
          </ButtonText>

          <ButtonText color="secondary">
            <Typography variant="overline">Button Text Secondary</Typography>
          </ButtonText>

          <ButtonText color="light">
            <Typography variant="overline">Button Text Light</Typography>
          </ButtonText>

          <ButtonText href="https://www.playform.io/" rel="noopener noreferrer" target="_blank">
            <Typography variant="overline">Button Link Default</Typography>
          </ButtonText>

          <ButtonText
            href="https://www.playform.io/"
            rel="noopener noreferrer"
            target="_blank"
            color="light"
          >
            <Typography variant="overline">Button Link Default Light</Typography>
          </ButtonText>
        </ButtonContainer>
      </div>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Button Project
        </Typography>

        <div>
          <ButtonProject icon="Movie" label="Default" />

          <ButtonProject
            icon="Movie"
            label="Default Long title new mix video clip 7 okay this long enough"
            noWrap
          />

          <ButtonProject icon="Movie" label="Progress 60% Default" progress={60} />

          <ButtonProject icon="Movie" label="Progress Default" progress />

          <ButtonProject icon="Movie" label="Default Disabled" disabled />

          <ButtonProject
            icon="Movie"
            label="Progress 60% Default Disabled"
            progress={60}
            disabled
          />

          <ButtonProject icon="Movie" label="Progress Default Disabled" progress disabled />

          <ButtonProject color="secondary" icon="Movie" label="Secondary" />

          <ButtonProject
            color="secondary"
            icon="Movie"
            label="Secondary Long title new mix video clip 7 okay this long enough"
            noWrap
          />

          <ButtonProject
            color="secondary"
            icon="Movie"
            label="Progress 60% Secondary"
            progress={60}
          />

          <ButtonProject color="secondary" icon="Movie" label="Progress Secondary" progress />

          <ButtonProject color="secondary" icon="Movie" label="Secondary Disabled" disabled />

          <ButtonProject
            color="secondary"
            icon="Movie"
            label="Progress 60% Secondary Disabled"
            progress={60}
            disabled
          />

          <ButtonProject
            color="secondary"
            icon="Movie"
            label="Progress Secondary Disabled"
            progress
            disabled
          />

          <ButtonProject icon="Movie" label="Secondary Alt" color="secondary-alt" />

          <ButtonProject
            icon="Movie"
            label="Progress 60% Secondary Alt"
            color="secondary-alt"
            progress={60}
          />

          <ButtonProject
            icon="Movie"
            label="Progress Secondary Alt"
            color="secondary-alt"
            progress
          />

          <ButtonProject
            icon="Movie"
            label="Secondary Alt Disabled"
            color="secondary-alt"
            disabled
          />

          <ButtonProject
            icon="Movie"
            label="Progress Secondary Alt Disabled"
            color="secondary-alt"
            progress
            disabled
          />

          <ButtonProject
            icon="Movie"
            label="Progress 60% Secondary Alt Disabled"
            color="secondary-alt"
            progress={60}
            disabled
          />
        </div>
      </div>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Misc
        </Typography>
      </div>
    </div>
  )
}

export default ButtonShowcase
