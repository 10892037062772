import React from 'react'
import Typography from '@mui/material/Typography'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import GridLabelOne from 'components/GridLabel/GridLabelOne'
import GridLabelTwo from 'components/GridLabel/GridLabelTwo'

const GridShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  return (
    <div style={{ padding: '30px 0' }}>
      <Typography variant="h6" paragraph>
        Grid Label One
      </Typography>

      <div style={{ width: 300, marginBottom: 16 }}>
        <GridLabelOne label="Current Balance" value={100} />
      </div>

      <Typography variant="h6" paragraph>
        Grid Label Two
      </Typography>

      <div style={{ width: 300, marginBottom: 16 }}>
        <GridLabelTwo
          label={
            <>
              <FullscreenIcon />
              <Typography className="ml-2" variant="overline">
                RESOLUTION
              </Typography>
            </>
          }
          content={<Typography variant="body2">1024x1024</Typography>}
        />
      </div>
    </div>
  )
}

export default GridShowcase
