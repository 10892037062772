import React, { ChangeEvent, useState } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import InputMaterial from '@mui/material/Input'
import RadioMaterial from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import OpenInNew from '@mui/icons-material/OpenInNew'
import TextFieldMaterial from '@mui/material/TextField'

import Checkbox from 'components/Checkbox'
import Link from 'components/Link'
import Input from 'components/Input'
import InputPhone from 'components/Input/InputPhone'
import InputSearch from 'components/Input/InputSearch'
import InputTimeField from 'components/Input/InputTimeField'
import Radio from 'components/Radio'
import TextField from 'components/TextField'
import TextFieldNumber from 'components/TextField/TextFieldNumber'
import InputWithSlider from 'components/Input/InputWithSlider'
import { THRESHOLD_LIMIT } from 'models/ApiModels'

const FormShowcase: React.FC<{
  scrollRef?: React.MutableRefObject<any>
}> = props => {
  const [inputSearchValue, setInputSearchValue] = useState('')
  const [inputSearchShow, setInputSearchShow] = useState(false)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [inputPhoneValue, setInputPhoneValue] = useState('')
  const [time, setTime] = useState(0)
  const [valueNumber, setValueNumber] = useState('')
  const [valueInputWithSlider, setValueInputWithSlider] = useState(0)

  const handleCheckboxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event?.target?.checked)
  }

  return (
    <div style={{ padding: '30px 0' }}>
      <Typography variant="h4">Form Showcase</Typography>

      <Divider style={{ margin: '20px 0' }} />

      <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Input Slider
        </Typography>

        <div className="mb-6">
          <InputWithSlider
            title="Threshold"
            value={valueInputWithSlider}
            min={THRESHOLD_LIMIT.MIN ?? 0}
            max={THRESHOLD_LIMIT.MIN ?? 0}
            onChange={(val: number) => {
              setValueInputWithSlider(val)
            }}
          />
        </div>
      </div>

      <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Text Fields
        </Typography>

        <div>
          <Typography variant="body1" component="div" paragraph>
            Text Field Material UI
          </Typography>

          <div className="mb-2">
            <TextFieldMaterial label="Primary Standard" variant="standard" />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Primary Standard Disabled" variant="standard" disabled />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Secondary Standard" color="secondary" variant="standard" />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Primary Outlined" />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Primary Outlined Disabled" disabled />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Secondary Outlined" color="secondary" />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Primary Filled" variant="filled" />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Secondary Filled" variant="filled" color="secondary" />
          </div>

          <div className="mb-2">
            <TextFieldMaterial label="Primary Filled Disabled" variant="filled" disabled />
          </div>
        </div>

        <div>
          <Typography variant="body1" component="div" paragraph>
            Text Field
          </Typography>

          <div className="mb-2">
            <TextField label="Primary Standard" variant="standard" />
          </div>

          <div className="mb-2">
            <TextField label="Secondary Standard" color="secondary" variant="standard" />
          </div>

          <div className="mb-2">
            <TextField label="Primary Outlined" />
          </div>

          <div className="mb-2">
            <TextField label="Secondary Outlined" color="secondary" />
          </div>

          <div className="mb-2">
            <TextField label="Primary Outlined Disabled" disabled />
          </div>

          <div className="mb-2">
            <TextField label="Primary Filled" variant="filled" />
          </div>

          <InputPhone
            value={inputPhoneValue}
            onChange={(phone: string) => {
              setInputPhoneValue(phone)
            }}
          />

          <div className="mb-2">
            <TextField label="Primary Alt Filled" variant="filled" color="primary-alt" />
          </div>

          <div className="mb-2">
            <TextField label="Secondary Filled" variant="filled" color="secondary" />
          </div>

          <div className="mb-2">
            <TextField label="Secondary Alt Filled" variant="filled" color="secondary-alt" />
          </div>

          <div className="mb-2">
            <TextField label="Primary Filled Disabled" variant="filled" disabled />
          </div>

          <div className="mb-2">
            <TextField
              label="Secondary Filled Small"
              size="small"
              variant="filled"
              color="secondary"
            />
          </div>

          <div className="mb-2">
            <TextField label="Secondary Filled Default Size" variant="filled" color="secondary" />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <Typography variant="h6" component="div" paragraph>
          Input
        </Typography>

        <div>
          <div className="mb-2">
            <Typography variant="body1" component="div" className="mb-2" paragraph>
              Input Material UI
            </Typography>

            <InputMaterial defaultValue="Default" className="mb-2" />
            <InputMaterial defaultValue="Primary" color="primary" className="mb-2" />
            <InputMaterial defaultValue="Secondary" color="secondary" className="mb-2" />
          </div>

          <div className="mb-2">
            <Typography variant="body1" component="div" className="mb-2" paragraph>
              Input
            </Typography>

            <Input defaultValue="Default" className="mb-2" />
            <Input defaultValue="Primary" color="primary" className="mb-2" />
            <Input defaultValue="Secondary" color="secondary" className="mb-2" />
            <Input defaultValue="Filled" variant="filled" className="mb-2" />
            <Input
              defaultValue="Filled Secondary"
              variant="filled"
              color="secondary"
              className="mb-2"
            />

            <Typography variant="body1" component="div" className="mb-2" paragraph>
              Input Tiny
            </Typography>

            <Input defaultValue="Tiny Default" size="tiny" className="mb-2" />
            <Input defaultValue="Tiny Primary" size="tiny" color="primary" className="mb-2" />
            <Input defaultValue="Tiny Secondary" size="tiny" color="secondary" className="mb-2" />
            <Input defaultValue="Tiny Filled" size="tiny" variant="filled" className="mb-2" />
            <Input
              defaultValue="Tiny Secondary"
              size="tiny"
              variant="filled"
              color="secondary"
              className="mb-2"
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <Input placeholder="New Project" />
        <InputMaterial placeholder="New Project" />
      </div>

      <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Input Formatted
        </Typography>

        <div>
          <TextFieldNumber
            value={valueNumber}
            onChange={event => {
              setValueNumber(event.target.value)
            }}
            min={0}
            scale={999999999999999999}
            placeholder="0000000000000000"
          />
        </div>

        <div>
          <TextFieldNumber
            // value={valueNumber}
            // onChange={event => {
            //   setValueNumber(event.target.value)
            // }}
            placeholder="0000 0000 0000 0000"
            mask="0000 0000 0000 0000"
            lazy={false}
            placeholderChar=" "
            value={valueNumber}
            onChange={event => {
              setValueNumber(event.target.value)
            }}
          />
        </div>
      </div>

      <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Input Time
        </Typography>

        <InputTimeField onChange={value => setTime(value)} value={time} />
      </div>

      <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Input Phone
        </Typography>

        <div>
          <InputPhone
            value={inputPhoneValue}
            onChange={(phone: string) => {
              setInputPhoneValue(phone)
            }}
          />
        </div>

        <div>
          <InputPhone
            value={inputPhoneValue}
            onChange={(phone: string) => {
              setInputPhoneValue(phone)
            }}
            disabled
            fullWidth
          />
        </div>
      </div>

      <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Input Search
        </Typography>

        <div className="mb-6">
          <InputSearch
            value={inputSearchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputSearchValue(event?.target?.value)
            }}
            placeholder="Search for a tag..."
          />

          <InputSearch
            value={inputSearchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputSearchValue(event?.target?.value)
            }}
            placeholder="Search for a tag..."
            size="small"
          />
        </div>

        <div className="mb-6">
          <InputSearch
            value={inputSearchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputSearchValue(event?.target?.value)
            }}
            placeholder="Search for a tag..."
            iconPosition="end"
          />
        </div>

        <div>
          <Typography variant="subtitle1" component="div">
            Input Search must placed inside parent with position relative
          </Typography>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-end',
              width: 350
            }}
          >
            <InputSearch
              // onClick={searchConfig?.onClick}
              // open={searchConfig?.open}
              open={inputSearchShow}
              setOpen={setInputSearchShow}
              background="#121212"
              placeholder="Search for a tag..."
              collapsible
            />
          </div>
        </div>
      </div>

      {/* <div className="mb-6">
        <Typography variant="h6" component="div" paragraph>
          Input Slider
        </Typography>

        <div className="mb-6">
          <InputWithSlider title="Threshold" value={valueInputWithSlider} min= />
        </div>
      </div> */}

      <div>
        <Typography variant="h6" component="div" paragraph>
          Radio
        </Typography>

        <div className="mb-6">
          <RadioMaterial color="default" />
          <RadioMaterial color="default" checked />

          <RadioMaterial color="default" disabled />
          <RadioMaterial color="default" disabled checked />

          <RadioMaterial color="primary" />
          <RadioMaterial color="primary" checked />

          <RadioMaterial color="primary" disabled />
          <RadioMaterial color="primary" disabled checked />

          <RadioMaterial color="secondary" />
          <RadioMaterial color="secondary" checked />

          <RadioMaterial color="secondary" disabled />
          <RadioMaterial color="secondary" disabled checked />

          <RadioMaterial />
          <RadioMaterial checked />

          <RadioMaterial disabled />
          <RadioMaterial disabled checked />
        </div>

        <div className="mb-6">
          <Radio color="default" />
          <Radio color="default" checked />

          <Radio color="default" disabled />
          <Radio color="default" disabled checked />

          <Radio color="primary" />
          <Radio color="primary" checked />

          <Radio color="primary" disabled />
          <Radio color="primary" disabled checked />

          <Radio color="secondary" />
          <Radio color="secondary" checked />

          <Radio color="secondary" disabled />
          <Radio color="secondary" disabled checked />

          <Radio />
          <Radio checked />

          <Radio disabled />
          <Radio disabled checked />
        </div>

        <div className="mb-6">
          <Radio label="Start" labelPlacement="start" />

          <Radio label="End" labelPlacement="end" />

          <Radio label="Top" labelPlacement="top" />

          <Radio label="Bottom" labelPlacement="bottom" />
        </div>

        <div className="mb-6">
          <Radio
            labelPlacement="end"
            label={
              <Typography variant="subtitle1">
                I consent to receiving Playform’s newsletter and other relevant email updates
              </Typography>
            }
          />
        </div>

        <div className="mb-6">
          <Radio
            label={
              <Typography variant="subtitle1">
                I acknowledge the
                <span className="ml-1">
                  <Link href="#" addOnRight={<OpenInNew />} target="_blank">
                    Terms and Conditions
                  </Link>
                </span>
              </Typography>
            }
          />
        </div>
      </div>

      <div>
        <Typography variant="h6" component="div" paragraph>
          Checkbox
        </Typography>

        <div className="mb-6">
          <Checkbox checked={checkboxChecked} onChange={handleCheckboxChanged} />

          <Checkbox color="default" checked={checkboxChecked} onChange={handleCheckboxChanged} />

          <Checkbox color="primary" checked={checkboxChecked} onChange={handleCheckboxChanged} />

          <Checkbox color="secondary" checked={checkboxChecked} onChange={handleCheckboxChanged} />

          <Checkbox
            color="secondary-alt"
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
          />
        </div>

        <Box maxWidth={400} marginBottom={3}>
          <Grid container alignItems="center">
            <Grid xs item>
              <Typography variant="body2">Social and Explore Updates</Typography>
            </Grid>

            <Grid xs="auto" item>
              <Checkbox
                labelPlacement="start"
                label={<Typography variant="overline">Subscribe</Typography>}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid xs item>
              <Typography variant="body2">Social and Explore Updates</Typography>
            </Grid>

            <Grid xs="auto" item>
              <Checkbox labelPlacement="start" label="Subscribe" />
            </Grid>
          </Grid>
        </Box>

        <div className="mb-6">
          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
            label="Start"
            labelPlacement="start"
          />

          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
            label="End"
            labelPlacement="end"
          />

          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
            label="Top"
            labelPlacement="top"
          />

          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
            label="Bottom"
            labelPlacement="bottom"
          />
        </div>

        <div className="mb-6">
          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
            label={
              <Typography variant="subtitle1">
                I consent to receiving Playform’s newsletter and other relevant email updates
              </Typography>
            }
          />
        </div>

        <div className="mb-6">
          <Checkbox
            checked={checkboxChecked}
            onChange={handleCheckboxChanged}
            label={
              <Typography variant="subtitle1">
                I acknowledge the
                <Box component="span" marginLeft={0.5}>
                  <Link href="#" addOnRight={<OpenInNew />} target="_blank">
                    Terms and Conditions
                  </Link>
                </Box>
              </Typography>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default FormShowcase
