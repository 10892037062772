import React from 'react'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import SimpleList from 'components/ListVirtualized/SimpleList'
import ArrowBulbIcon from 'components/Icon/ArrowBulbIcon'
import ClapIcon from 'components/Icon/ClapIcon'
import ClappedIcon from 'components/Icon/ClappedIcon'
import CreditCardIcon from 'components/Icon/CreditCardIcon'
import DownloadIcon from 'components/Icon/DownloadIcon'
import EraseIcon from 'components/Icon/EraseIcon'
import EthereumIcon from 'components/Icon/EthereumIcon'
import FacebookSquareIcon from 'components/Icon/FacebookSquareIcon'
import FashionIcon from 'components/Icon/FashionIcon'
import FreeformIcon from 'components/Icon/FreeformIcon'
import InstagramSquareIcon from 'components/Icon/InstagramSquareIcon'
import LinkedinSquareIcon from 'components/Icon/LinkedinSquareIcon'
import LogoIcon from 'components/Icon/LogoIcon'
import PanelEyeIcon from 'components/Icon/PanelEyeIcon'
import PolygonIcon from 'components/Icon/PolygonIcon'
import RoundRoundIcon from 'components/Icon/RoundRoundIcon'
import SlackIcon from 'components/Icon/SlackIcon'
import SquareRoundIcon from 'components/Icon/SquareRoundIcon'
import ProArtFilterIcon from 'components/Icon/ProArtFilterIcon'
import TwitterSquareIcon from 'components/Icon/TwitterSquareIcon'
import VideoPreviewIcon from 'components/Icon/VideoPreviewIcon'

const DATA_ICONS = [
  {
    value: 'ArrowBulbIcon',
    component: ArrowBulbIcon
  },
  {
    value: 'ClapIcon',
    component: ClapIcon
  },
  {
    value: 'ClappedIcon',
    component: ClappedIcon
  },
  {
    value: 'CreditCardIcon',
    component: CreditCardIcon
  },
  {
    value: 'DownloadIcon',
    component: DownloadIcon
  },
  {
    value: 'EraseIcon',
    component: EraseIcon
  },
  {
    value: 'EthereumIcon',
    component: EthereumIcon
  },
  {
    value: 'FacebookSquareIcon',
    component: FacebookSquareIcon
  },
  {
    value: 'FashionIcon',
    component: FashionIcon
  },
  {
    value: 'FreeformIcon',
    component: FreeformIcon
  },
  {
    value: 'InstagramSquareIcon',
    component: InstagramSquareIcon
  },
  {
    value: 'LinkedinSquareIcon',
    component: LinkedinSquareIcon
  },
  {
    value: 'LogoIcon',
    component: LogoIcon
  },
  {
    value: 'PanelEyeIcon',
    component: PanelEyeIcon
  },
  {
    value: 'PolygonIcon',
    component: PolygonIcon
  },
  {
    value: 'ProArtFilterIcon',
    component: ProArtFilterIcon
  },
  {
    value: 'RoundRoundIcon',
    component: RoundRoundIcon
  },
  {
    value: 'SlackIcon',
    component: SlackIcon
  },
  {
    value: 'SquareRoundIcon',
    component: SquareRoundIcon
  },
  {
    value: 'TwitterSquareIcon',
    component: TwitterSquareIcon
  },
  {
    value: 'VideoPreviewIcon',
    component: VideoPreviewIcon
  }
]

const IconsShowcase: React.FC<{}> = props => {
  return (
    <div style={{ padding: '30px 0' }}>
      <Typography variant="h4">Icons Showcase</Typography>

      <Divider style={{ margin: '20px 0' }} />

      <Typography variant="h6" paragraph>
        Custom Icons
      </Typography>

      <div className="px-6">
        <SimpleList
          dataLength={DATA_ICONS.length}
          config={{
            default: {
              columns: 4,
              gutter: 24
            }
          }}
          renderItem={({ index }) => {
            const item = DATA_ICONS[index]
            if (!item) return null
            const { component: Component, value } = item
            return (
              <div className="text-center">
                <div>
                  <Component />
                </div>

                <Typography variant="caption">{value}</Typography>
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

export default IconsShowcase
