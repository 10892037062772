import React from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import ButtonMaterial from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/system'

type ButtonShowcaseProps = {}

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  '& > *': {
    marginRight: '10px'
  }
})

const ButtonShowcase: React.FC<ButtonShowcaseProps> = props => {
  return (
    <div>
      <Typography variant="h6" paragraph>
        Button Base Material UI
      </Typography>

      <ButtonBase>Button Base</ButtonBase>

      <Typography variant="h6" paragraph>
        Button Material UI
      </Typography>

      <ButtonContainer>
        <ButtonMaterial size="large">Primary</ButtonMaterial>

        <ButtonMaterial>Primary</ButtonMaterial>

        <ButtonMaterial size="small">Primary</ButtonMaterial>

        <ButtonMaterial startIcon={<AddIcon />}>Primary</ButtonMaterial>

        <ButtonMaterial size="small" startIcon={<AddIcon />}>
          Primary
        </ButtonMaterial>

        <IconButton size="medium">
          <AddIcon />
        </IconButton>

        <IconButton>
          <AddIcon />
        </IconButton>

        <IconButton size="small">
          <AddIcon />
        </IconButton>

        <ButtonMaterial startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="secondary" size="large">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary">Secondary</ButtonMaterial>

        <ButtonMaterial color="secondary" size="small">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" size="small" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <IconButton color="secondary">
          <AddIcon />
        </IconButton>

        <IconButton color="secondary" size="small">
          <AddIcon />
        </IconButton>

        <ButtonMaterial color="secondary" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="info" size="large">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info">Info</ButtonMaterial>

        <ButtonMaterial color="info" size="small">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" size="small" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <IconButton color="info">
          <AddIcon />
        </IconButton>

        <IconButton color="info" size="small">
          <AddIcon />
        </IconButton>

        <ButtonMaterial color="info" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="warning" size="large">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning">Warning</ButtonMaterial>

        <ButtonMaterial color="warning" size="small">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" size="small" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <IconButton color="warning">
          <AddIcon />
        </IconButton>

        <IconButton color="warning" size="small">
          <AddIcon />
        </IconButton>

        <ButtonMaterial color="warning" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="success" size="large">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success">Success</ButtonMaterial>

        <ButtonMaterial color="success" size="small">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" size="small" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <IconButton color="success">
          <AddIcon />
        </IconButton>

        <IconButton color="success" size="small">
          <AddIcon />
        </IconButton>

        <ButtonMaterial color="success" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="error" size="large">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error">Error</ButtonMaterial>

        <ButtonMaterial color="error" size="small">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" size="small" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <IconButton color="error">
          <AddIcon />
        </IconButton>

        <IconButton color="error" size="small">
          <AddIcon />
        </IconButton>

        <ButtonMaterial color="error" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      {/* Button Outlined */}
      <ButtonContainer>
        <ButtonMaterial variant="outlined" size="large">
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="outlined">Primary</ButtonMaterial>

        <ButtonMaterial variant="outlined" size="small">
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="outlined" startIcon={<AddIcon />}>
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="outlined" size="small" startIcon={<AddIcon />}>
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="outlined" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="secondary" variant="outlined" size="large">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="outlined">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="outlined" size="small">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="outlined" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="outlined" size="small" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="outlined" disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="info" variant="outlined" size="large">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="outlined">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="outlined" size="small">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="outlined" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="outlined" size="small" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="outlined" disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="warning" variant="outlined" size="large">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="outlined">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="outlined" size="small">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="outlined" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="outlined" size="small" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="outlined" disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="success" variant="outlined" size="large">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="outlined">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="outlined" size="small">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="outlined" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="outlined" size="small" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="outlined" disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="error" variant="outlined" size="large">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="outlined">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="outlined" size="small">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="outlined" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="outlined" size="small" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="outlined" disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      {/* Button Contained */}
      <ButtonContainer>
        <ButtonMaterial variant="contained" size="large">
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="contained">Primary</ButtonMaterial>

        <ButtonMaterial variant="contained" size="small">
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="contained" size="large" startIcon={<AddIcon />}>
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="contained" startIcon={<AddIcon />}>
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="contained" size="small" startIcon={<AddIcon />}>
          Primary
        </ButtonMaterial>

        <ButtonMaterial variant="contained" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="secondary" variant="contained" size="large">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="contained">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="contained" size="small">
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="contained" size="large" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="contained" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>
          Secondary
        </ButtonMaterial>

        <ButtonMaterial color="secondary" variant="contained" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="info" variant="contained" size="large">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="contained">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="contained" size="small">
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="contained" size="large" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="contained" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="contained" size="small" startIcon={<AddIcon />}>
          Info
        </ButtonMaterial>

        <ButtonMaterial color="info" variant="contained" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="warning" variant="contained" size="large">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="contained">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="contained" size="small">
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="contained" size="large" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="contained" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="contained" size="small" startIcon={<AddIcon />}>
          Warning
        </ButtonMaterial>

        <ButtonMaterial color="warning" variant="contained" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="success" variant="contained" size="large">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="contained">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="contained" size="small">
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="contained" size="large" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="contained" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="contained" size="small" startIcon={<AddIcon />}>
          Success
        </ButtonMaterial>

        <ButtonMaterial color="success" variant="contained" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>

      <ButtonContainer>
        <ButtonMaterial color="error" variant="contained" size="large">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="contained">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="contained" size="small">
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="contained" size="large" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="contained" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="contained" size="small" startIcon={<AddIcon />}>
          Error
        </ButtonMaterial>

        <ButtonMaterial color="error" variant="contained" startIcon={<AddIcon />} disabled>
          Disabled
        </ButtonMaterial>
      </ButtonContainer>
    </div>
  )
}

export default ButtonShowcase
