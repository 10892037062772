import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'

import Button from 'components/Button'
import DropdownMenu from 'components/DropdownMenu'

type DropdownShowcaseProps = {
  scrollRef?: React.MutableRefObject<any>
}

const DropdownContainer = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  '& > *': {
    marginRight: '10px'
  }
})

const items = [
  {
    key: 1,
    label: 'Duplicate',
    onClick: () => {
      console.log('Menu 1 Clicked')
    }
  },
  {
    key: 2,
    label: 'Share',
    onClick: () => {
      console.log('Menu 2 Clicked')
    }
  },
  {
    key: 3,
    label: 'Make Private',
    onClick: () => {
      console.log('Menu 3 Clicked')
    }
  },
  {
    key: 4,
    label: 'Delete',
    onClick: () => {
      console.log('Menu 4 Clicked')
    }
  }
]

const buttonComponent = (props: any) => {
  const { buttonRef, ...restProps } = props

  return (
    <Button {...restProps} ref={buttonRef}>
      Test
    </Button>
  )
}

const DropdownShowcase: React.FC<DropdownShowcaseProps> = props => {
  return (
    <div style={{ padding: '30px 0' }}>
      <DropdownContainer>
        <Typography variant="body2">Icon Only: </Typography>
        <DropdownMenu items={items} />
      </DropdownContainer>

      <DropdownContainer>
        <Typography variant="body2">Text Default : </Typography>
        <DropdownMenu buttonLabel="Download" items={items} />
      </DropdownContainer>

      <DropdownContainer>
        <Typography variant="body2">Button Component :</Typography>
        <DropdownMenu CustomButtonComponent={buttonComponent} items={items} />
      </DropdownContainer>

      <div className="mb-4">
        <Typography variant="h6" paragraph>
          Dropdown Color
        </Typography>
        <div className="flex items-center">
          <DropdownMenu items={items} />
          <DropdownMenu buttonLabel="Download" items={items} />
          <DropdownMenu items={items} color="light" />
          <DropdownMenu buttonLabel="Download" items={items} color="light" />
        </div>
      </div>

      <div>
        <Typography variant="h6" paragraph>
          Dropdown Size
        </Typography>
        <div>Normal</div>
        <div className="flex items-center">
          <DropdownMenu items={items} />
          <DropdownMenu buttonLabel="Download" items={items} />
        </div>
        <div>Small</div>
        <div className="flex items-center">
          <DropdownMenu items={items} buttonLabelSize="small" color="light" />
          <DropdownMenu
            buttonLabel="Download"
            buttonLabelSize="small"
            items={items}
            color="light"
          />
        </div>
      </div>
    </div>
  )
}

export default DropdownShowcase
