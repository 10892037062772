import React from 'react'
import Typography from '@mui/material/Typography'
import Button from 'components/Button'
import { RootActionType, RootState } from 'duck'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { apiSelectors } from 'duck/ApiDuck'
import { bannerActions } from 'duck/AppDuck/BannerDuck'
import { ReduxProps } from 'utils/Types'
// import { BannerSubscriptionUpsellProps } from 'components/Banner/BannerSubscriptionUpsell'

const mapStateToProps = (state: RootState) => ({
  currentProject: apiSelectors.currentProject(state)
})

const mapDispatchToProps = (dispatch: Dispatch<RootActionType>) =>
  bindActionCreators(
    {
      showUpsellBanner: bannerActions.upsell.show
    },
    dispatch
  )

export type BannerShowcaseProps = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>

const BannerShowcase: React.FC<BannerShowcaseProps> = props => {
  const { showUpsellBanner } = props

  // const [activeBannerState, setActiveBannerState] = useState({
  //   onClickUpgrade: () => {},
  //   position: 'float-bottom-banner-overlay' as BannerSubscriptionUpsellProps['position'],
  //   show: false
  // })

  return (
    <div>
      <Typography variant="h5" paragraph>
        Banner Subscription Panel
      </Typography>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Position
        </Typography>

        <div>
          <Button
            onClick={() => {
              showUpsellBanner({
                dismissable: false,
                upsellLocation: 'global',
                contentMode: 'batch-download',
                position: 'inside-content'
              })
            }}
          >
            Banner Inside Content
          </Button>

          <Button
            onClick={() => {
              showUpsellBanner({
                dismissable: false,
                upsellLocation: 'global',
                contentMode: 'batch-download',
                position: 'float-bottom-banner'
              })
            }}
          >
            Banner Float Bottom
          </Button>

          <Button
            onClick={() => {
              showUpsellBanner({
                dismissable: false,
                upsellLocation: 'global',
                contentMode: 'batch-download',
                position: 'float-bottom-banner-overlay'
              })
            }}
          >
            Banner Float Bottom with Overlay
          </Button>
        </div>
      </div>

      <div className="mb-6">
        <Typography variant="h6" paragraph>
          Content
        </Typography>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerShowcase)
