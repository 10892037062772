import React, { useState } from 'react'
import _get from 'lodash/get'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { generateOutputImages } from 'utils/mockGenerator'

import Img from 'components/Img'
import RcSliderImages from 'components/RcSlider/RcSliderImages'
import RcSlider from 'components/RcSlider'
import Slider from 'components/Slider'

type SliderShowcaseProps = {}

const getSliderDefaultState = (max: number, total?: number) => ({
  value: 0,
  max,
  total: total ? total : max,
  disabled: false,
  imageGrid: generateOutputImages(max)
})

const SliderShowcase: React.FC<SliderShowcaseProps> = props => {
  const [sliderMaterialState, setSliderMaterialState] = useState(30)
  const [sliderState, setSliderState] = useState(30)
  const [slider2State, setSlider2State] = useState(30)
  const [sliderImagesState, setSliderImagesState] = useState(getSliderDefaultState(20))
  const [sliderImages2State, setSliderImages2State] = useState(getSliderDefaultState(5))
  const [sliderImages3State, setSliderImages3State] = useState(getSliderDefaultState(25))
  const [sliderImages4State, setSliderImages4State] = useState(getSliderDefaultState(200))
  const [sliderImages5State, setSliderImages5State] = useState(getSliderDefaultState(50, 100))
  const [sliderImages6State, setSliderImages6State] = useState(getSliderDefaultState(99, 120))
  const [sliderImages7State, setSliderImages7State] = useState(getSliderDefaultState(15, 200))
  const [sliderImages8State, setSliderImages8State] = useState(getSliderDefaultState(500))

  const onChangeSliderMaterial = (event: Event, value: number | number[]) => {
    setSliderMaterialState(value as number)
  }

  const onChangeSlider = (value: number | number[]) => {
    setSliderState(value as number)
  }

  const onChangeSlider2 = (value: number | number[]) => {
    setSlider2State(value as number)
  }

  const onChangeSliderImages = (value: number) => {
    setSliderImagesState({ ...sliderImagesState, value })
  }

  const onChangeSliderImages2 = (value: number) => {
    setSliderImages2State({ ...sliderImages2State, value })
  }
  const onChangeSliderImages3 = (value: number) => {
    setSliderImages3State({ ...sliderImages3State, value })
  }

  const onChangeSliderImages4 = (value: number) => {
    setSliderImages4State({ ...sliderImages4State, value })
  }
  const onChangeSliderImages5 = (value: number) => {
    setSliderImages5State({ ...sliderImages5State, value })
  }

  const onChangeSliderImages6 = (value: number) => {
    setSliderImages6State({ ...sliderImages6State, value })
  }
  const onChangeSliderImages7 = (value: number) => {
    setSliderImages7State({ ...sliderImages7State, value })
  }

  const onChangeSliderImages8 = (value: number) => {
    setSliderImages8State({ ...sliderImages8State, value })
  }

  return (
    <div>
      <Typography variant="h4">Slider</Typography>

      <Divider style={{ margin: '20px 0' }} />

      <Typography variant="h6" paragraph>
        Slider Material UI
      </Typography>

      <div>
        <Typography variant="subtitle2">Default</Typography>
        <Slider value={sliderMaterialState} onChange={onChangeSliderMaterial} />

        <Typography variant="subtitle2">Default Disabled</Typography>
        <Slider value={sliderMaterialState} onChange={onChangeSliderMaterial} disabled />

        <Typography variant="subtitle2">Primary</Typography>
        <Slider value={sliderMaterialState} onChange={onChangeSliderMaterial} color="primary" />
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="primary"
          marks
        />

        <Typography variant="subtitle2">Primary Disabled</Typography>
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="primary"
          disabled
        />

        <Typography variant="subtitle2">Secondary</Typography>
        <Slider value={sliderMaterialState} onChange={onChangeSliderMaterial} color="secondary" />
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="secondary"
          marks
        />

        <Typography variant="subtitle2">Secondary Disabled</Typography>
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="secondary"
          disabled
        />

        <Typography variant="subtitle2">Secondary Disabled Thumb</Typography>
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="secondary"
          disabledThumb
        />

        <Typography variant="subtitle2">Default Without Track</Typography>
        <Slider value={sliderMaterialState} onChange={onChangeSliderMaterial} track={false} />

        <Typography variant="subtitle2">Secondary Without Track</Typography>
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="secondary"
          track={false}
        />

        <Typography variant="subtitle2">Secondary Alt</Typography>
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="secondary-alt"
        />
        <Slider
          value={sliderMaterialState}
          onChange={onChangeSliderMaterial}
          color="secondary-alt"
          marks
        />

        {sliderMaterialState}
      </div>

      <Typography variant="h6" paragraph>
        RC Slider
      </Typography>

      <div className="mb-2">
        <Typography variant="subtitle1">Color Default</Typography>

        <RcSlider
          min={0}
          max={100}
          step={1}
          onChange={onChangeSlider}
          value={sliderState}
          color="default"
        />

        {sliderState}
      </div>

      <div className="mb-2">
        <Typography variant="subtitle2">Color Primary</Typography>

        <RcSlider min={0} max={100} step={1} onChange={onChangeSlider2} value={slider2State} />

        {slider2State}
      </div>

      <Typography variant="h6" paragraph>
        Slider Images Grid
      </Typography>

      <RcSliderImages {...sliderImagesState} onChangeSlider={onChangeSliderImages} />

      <Typography variant="h6" paragraph>
        Slider Images Single
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages2State.imageGrid[sliderImages2State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages2State} onChangeSlider={onChangeSliderImages2} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages3State.imageGrid[sliderImages3State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages3State} onChangeSlider={onChangeSliderImages3} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages4State.imageGrid[sliderImages4State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages4State} onChangeSlider={onChangeSliderImages4} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages5State.imageGrid[sliderImages5State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages5State} onChangeSlider={onChangeSliderImages5} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages6State.imageGrid[sliderImages6State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages6State} onChangeSlider={onChangeSliderImages6} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages7State.imageGrid[sliderImages7State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages7State} onChangeSlider={onChangeSliderImages7} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Img
          style={{ width: '200px' }}
          src={_get(sliderImages8State.imageGrid[sliderImages8State.value - 1], 'file')}
        />
        <RcSliderImages {...sliderImages8State} onChangeSlider={onChangeSliderImages8} />
      </div>
    </div>
  )
}

export default SliderShowcase
