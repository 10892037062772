import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'

import Img from 'components/Img'
import ImgScrambled from 'components/Img/ImgScrambled'
// import { generateScrambledImage } from 'utils/mockGenerator'
import { getScrambledImage } from 'models/ApiModelsDummy'
import ImageCard from 'components/DataCard/ImageCard'

import ImgSquareExample from 'assets/images/frame-result.jpg'

const imgScrambledSquare = getScrambledImage()

type ImageShowcaseProps = {
  scrollRef?: React.MutableRefObject<any>
}

const ImageContainer = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  '& > *': {
    marginRight: '10px'
  }
})

const imageObject = {
  id: 1,
  user: '898',
  height: 300,
  width: 400,
  file: 'https://picsum.photos/id/122/300/400',
  thumbnail: 'https://picsum.photos/id/122/128/128',
  created: ''
}

const ImageShowcase: React.FC<ImageShowcaseProps> = props => {
  const [bookmarked, setBookmarked] = useState(true)
  const [selected, setSelected] = useState(true)

  return (
    <div style={{ padding: '30px 0' }}>
      {/* <Typography variant="h4">Image Showcase</Typography>
      <Divider style={{ margin: '20px 0' }} /> */}

      <div>
        <Typography variant="h6" paragraph>
          Image Card
        </Typography>

        <img src={ImgSquareExample} alt="" />

        <div className="flex">
          <ImageCard
            src={ImgSquareExample}

            // containerHeight={height}
            // containerWidth={width}
            // userImage={item.image}
            // to={onClickImageGoTo(item)}
            // onLoad={measure}
            // onClickBookmark={onClickBookmark}
            // enhanced={Boolean(item.image.adjust)}
          />

          <ImageCard
            src={ImgSquareExample}
            onClickBookmark={() => {
              setBookmarked(prev => !prev)
            }}
            onClick={() => {
              setSelected(prev => !prev)
            }}
            bookmarked={bookmarked}
            selected={selected}
            enhanced

            // containerHeight={height}
            // containerWidth={width}
            // userImage={item.image}
            // to={onClickImageGoTo(item)}
            // onLoad={measure}
            // onClickBookmark={onClickBookmark}
            // enhanced={Boolean(item.image.adjust)}
          />
        </div>
      </div>

      <Typography variant="h6" paragraph>
        Image
      </Typography>

      <ImageContainer>
        <Img
          src={'https://picsum.photos/id/12/5000/2560'}
          style={{ width: '250px', height: '128px' }}
        />
        <Img image={imageObject} />
        <Img />
        <Img src={'https://picsum.photos/id/12/250/128'} />

        <div>
          <Img
            src={'https://picsum.photos/id/12/5000/2560'}
            style={{ width: '250px', height: '128px', border: '1px solid #fff' }}
          />
        </div>
      </ImageContainer>

      <Typography variant="h6" paragraph>
        Image Scrambled
      </Typography>

      <div>
        <ImgScrambled
          alt={imgScrambledSquare?.alt}
          srcScrambled={imgScrambledSquare}
          width={500}
          height={500}
          helper
        />
      </div>
    </div>
  )
}

export default ImageShowcase
